    .App {
        display: flex;
        flex-direction: row;
        height: 100vh;
        background-color: #FFF;
    }

    .swagger-ui .info {
        background-color: white;
        padding: 20px;
        border-bottom: 3px solid #B32727;
        /* font-family:  */
    }

    .swagger-ui .info .description p {
        color: black;
    }

    .swagger-ui .info .description .markdown {
        color: black;
    }

    .swagger-ui .scheme-container {
        background-color: rgba(1, 1, 1, 0);
        box-shadow: 0 0 0 0;
    }

    .swagger-ui .schemes-title {
        color: white;
    }

    .swagger-ui .opblock-tag {
        color: rgb(41, 30, 30);
        text-transform: uppercase;
        letter-spacing: .1em;
        border-bottom: 1px solid #D4BE7D;
    }

    .swagger-ui .opblock-tag small {
        color: white;
    }

    .swagger-ui .op-block {
        border-radius: 0;
    }

    .swagger-ui .opblock .opblock-summary-path {
        color: #198754;
    }

    .swagger-ui .opblock .opblock-summary-description {
        color: rgb(41, 30, 30);
    }

    .swagger-ui .markdown {
        color: black;
    }

    .swagger-ui .markdown p {
        color: rgb(41, 30, 30);
    }

    .swagger-ui .table-container {
        color: rgb(41, 30, 30);
    }

    .swagger-ui .response-col_status {
        color: rgb(41, 30, 30);
    }

    .swagger-ui .responses-header td {
        color: rgb(41, 30, 30);
    }

    .swagger-ui .table-container th {
        color: rgb(41, 30, 30);
    }

    .swagger-ui .table-container .parameter__name {
        color: rgb(41, 30, 30);
    }

    .swagger-ui section.models {
        border: 1px solid #D4BE7D;
        border-radius: 0;
    }

    .swagger-ui section.models h4 {
        color: rgb(41, 30, 30);
    }

    .swagger-ui section.models .model-container {
        background-color: white;
        border-radius: 0;
    }

    .swagger-ui section.models .model-container:hover {
        background-color: whitesmoke;
    }

    .sso {
        margin: 30px;
    }

    .sso h1 {
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-size: 1.85714em;
        line-height: 0.6em;
    }

    .sso h3 {
        font-family: "Roboto", sans-serif;
        font-weight: bold;
        font-size: 16px;
        line-height: 0.6em;
        padding-top: 20px;
    }

    .sso h4 {
        font-family: "Roboto", sans-serif;
        font-weight: bold;
        font-size: 14px;
        line-height: 0.6em;
        padding-top: 10px;
        color: #B32727;
        text-decoration: underline
    }

    .sso li {
        padding-top: 7px;
    }

    .sso code {
        color: rgb(229, 57, 53);
        background-color: rgba(38, 50, 56, 0.05);
        font-family: Courier, monospace;
        border-radius: 2px;
        border: 1px solid rgba(38, 50, 56, 0.1);
        padding: 0px 5px;
        font-size: 13px;
        font-weight: 400;
        word-break: break-word;
    }

    .sso table {
        display: block;
        width: 100%;
        overflow: auto;
        word-break: keep-all;
        border-collapse: collapse;
        border-spacing: 0px;
        margin-top: 1.5em;
        margin-bottom: 1.5em;
        border-color: 1px grey solid;
    }

    .sso table tr {
        background-color: rgb(255, 255, 255);
        border: 1px solid rgb(221, 221, 221);
        padding: 10px;
    }

    .sso table th {
        background-color: rgb(255, 255, 255);
        border: 1px solid rgb(221, 221, 221);
        padding: 10px;
    }

    .sso table td {
        text-align: left;
        padding: 6px 13px;
        border: 1px solid rgb(221, 221, 221);
        padding: 10px;
    }

    .sso .type_method {
        color: blue;
        background-color: rgba(38, 50, 56, 0.05);
        font-family: Courier, monospace;
        border-radius: 2px;
        border: 1px solid rgba(38, 50, 56, 0.1);
        padding: 0px 5px;
        font-size: 13px;
        font-weight: 400;
        word-break: break-word;
        margin-left: 8px;
        margin-right: 8px;
    }
